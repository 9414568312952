const ContactForm = {
  components: {
    turnstile: TurnstileWidget,
  },
  data() {
    return {
      email: '',
      full_name: '',
      subject: '',
      message: '',
      turnstile: '',
      tos: '',
      error: '',
      success: '',
      loading: false,
    };
  },
  computed: {
    user() {
      return domestiko.user;
    },
    submitDisabled: function () {
      return (
        (!this.user && this.full_name === '') ||
        (!this.user && this.email === '') ||
        (!this.user && !this.email.match(/[^@\s]+@[^@\s]+\.[^@\s]+/)) ||
        this.subject === '' ||
        this.message === '' ||
        !this.tos ||
        this.loading
      );
    },
  },
  methods: {
    captcha(token) {
      this.turnstile = token;
    },
    submit() {
      this.error = '';
      this.success = '';
      this.loading = true;

      axios
        .post('/contacto/', {
          email: this.user?.email || this.email,
          full_name: this.user?.username || this.full_name,
          subject: this.subject,
          message: this.message,
          turnstile: this.turnstile,
        })
        .then((res) => {
          this.error = '';
          this.subject = '';
          this.message = '';
          this.success =
            'Gracias por su mensaje, nos pondremos en contacto contigo lo antes posible.';
          console.log('Contact OK!', res.data);
        })
        .catch((err) => {
          console.log('Contact KO!', err.response);
          if (err.response) this.error = err.response.data.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  template: `
    <form class="contact-form uk-form-stacked" :class="{loading: loading}">
      <template v-if="success">
        <div class="uk-alert uk-alert-success">
          {{ success }}
        </div>
      </template>
      <div v-else>
        <div v-if="!user">
          <div class="uk-margin-top">
            <label>Nombre completo</label>
            <div class="uk-form-controls">
              <input type="text" class="uk-input" v-model="full_name">
            </div>
          </div>
          <div class="uk-margin-top">
            <label>Correo electrónico</label>
            <div class="uk-form-controls">
              <input type="email" class="uk-input" autocomplete="email" v-model="email">
            </div>
          </div>
        </div>
        <div class="uk-margin-top">
          <label>Asunto</label>
          <div class="uk-form-controls">
            <input type="text" class="uk-input" v-model="subject">
          </div>
        </div>
        <div class="uk-margin-top">
          <label>Mensaje</label>
          <div class="uk-form-controls">
            <textarea class="uk-textarea" v-model="message" rows="5"></textarea>
          </div>
        </div>
          <template v-if="error">
          <div class="uk-alert uk-alert-warning">
            {{ error }}
          </div>
        </template>
        <div class="uk-margin-top">
          <p>
            <label>
              <input type="checkbox" class="uk-checkbox" v-model="tos" />
              He leído las
              <a href="/info/nota-legal/" target="_blank">condiciones de servicio</a>
              y la
              <a href="/info/politica-de-privacidad/" target="_blank">política de privacidad de Doméstiko</a>
              y acepto el tratamiento de mis datos para recibir la información solicitada.
            </label>
          </p>
          <p class="legal -tos">
            Responsable del Tratamiento: Luis G. Valdivieso; finalidad del tratamiento: prestación del servicio; legitimación del tratamiento: consentimiento del interesado; destinatarios: otros usuarios del sitio web, colaboradores en la prestación del servicio y autoridades cuando se nos requiera legalmente; derechos: acceder, rectificar y suprimir los datos, así como otros derechos, como se explica en la información adicional.
            Derechos: correo a <a href="mailto:privacidad@domestiko.com">privacidad@domestiko.com</a>.
            Más información en la <a href="/info/politica-de-privacidad/" target="_blank">política de privacidad</a> y <a href="/info/politica-de-cookies/" target="_blank">cookies</a>.
          </p>
          <turnstile sitekey="0x4AAAAAAADlfYVQcaumImbF" @verify="captcha" />
          <button class="uk-button uk-button-primary" @click.prevent="submit" :disabled="submitDisabled">
            Enviar mensaje
          </button>
        </div>
      </div>
    </form>
  `,
};

(function () {
  ['block-contact-form'].map((el) => {
    new Vue({
      el: document.getElementById(el),
      components: {
        'contact-form': ContactForm,
      },
    });
  });
})();
