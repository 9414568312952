(function (elements) {
  if (!elements) return;

  const jobSearchForm = {
    data: {
      regional: '',
      actividad: '',
      modo: urlQueryParams('mode') || '',
      tipo: urlQueryParams('type') || '',
      fecha: urlQueryParams('date') || '',
      isMounted: false,
      modalidades: [
        {
          id: 'FULL_TIME',
          label: 'Tiempo completo',
        },
        {
          id: 'PART_TIME',
          label: 'Tiempo parcial',
        },
        {
          id: 'TEMPORARY',
          label: 'Temporal',
        },
        {
          id: 'PER_DIEM',
          label: 'Por día/hora',
        },
        {
          id: 'CONTRACTOR',
          label: 'Por obra',
        },
        {
          id: 'OTHER',
          label: 'Otro',
        },
      ],
      dates: [
        {
          id: '24h',
          label: '24 horas',
        },
        {
          id: '48h',
          label: '48 horas',
        },
        {
          id: '7d',
          label: '7 días',
        },
        {
          id: '15d',
          label: '15 dias',
        },
      ],
    },
    components: {
      'term-search': TermSearch,
    },
    mounted() {
      this.isMounted = true;
      if (this.$refs.regional) {
        const selected = this.$refs.regional.querySelector(
          'option[selected]:not([disabled])',
        );
        if (selected) {
          this.regional = JSON.parse(selected.value);
        }
      }
    },
    methods: {
      log() {
        console.log(...arguments);
      },
      search: function (from) {
        let url = [];
        if (this.actividad) url.push(this.actividad.slug);
        if (this.regional) url.push(this.regional.slug);
        if (url.length === 0) {
          if (from) {
            url.push(from);
          } else {
            document.location = '/empleo/localidades/';
            return;
          }
        }

        url = '/' + url.join('/') + '/';
        if (!url.includes('/empleo/')) {
          url = '/empleo/' + url;
        }

        const dest = new URL(location.origin);
        dest.pathname = url.replace(/\/{2,}/gi, '/');

        if (this.modo) {
          dest.searchParams.set('mode', this.modo);
        }

        if (this.tipo) {
          dest.searchParams.set('type', this.tipo);
        }
        if (this.fecha) {
          dest.searchParams.set('date', this.fecha);
        }

        console.log('jobs.search', dest.toString());
        location = dest;
      },
      clear: function () {
        this.regional = '';
        this.actividad = '';
      },
    },
  };

  Array.from(elements).forEach((el) => {
    new Vue(jobSearchForm).$mount(el);
  });
})(document.getElementsByClassName('jobs-search-form'));
