(function (el) {
  if (!el) return;

  new Vue({
    el: el,
    data: {
      regional: '',
      actividad: '',
      tipo: urlQueryParams('type') || '',
      isMounted: false,
    },
    components: {
      'term-search': TermSearch,
    },

    created() {
      this.isMounted = true;
    },
    methods: {
      search: function () {
        var url = [];
        if (this.actividad) url.push(this.actividad.slug);
        if (this.regional) url.push(this.regional.slug);
        if (url.length === 0) {
          UIkit.modal.alert('Por favor introduce algún criterio de búsqueda');
          return;
        }
        url = '/' + url.join('/') + '/';
        if (this.tipo) {
          url += '?type=' + this.tipo;
        }
        console.log('profiles.search', url);
        location = url;
      },
      clear: function () {
        this.regional = '';
        this.actividad = '';
      },
    },
  });
})(document.getElementsByClassName('profiles-search-form')[0]);
